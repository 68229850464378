const INDUSTRY_LIST = ['Agnostic',
  'Agriculture and Allied Industries',
  'Automobiles',
  'Auto Components',
  'Aviation',
  'Banking',
  'Biotechnology',
  'Cement',
  'Chemicals',
  'Consumer Durables',
  'Defence Manufacturing',
  'E-Commerce',
  'Education and Training',
  'Electronics System Design and Manufacturing',
  'Engineering and Capital Goods',
  'Financial Services',
  'FMCG',
  'Gems and Jewellery',
  'Healthcare',
  'Infrastructure',
  'Insurance',
  'IT and BPM',
  'Manufacturing',
  'Media and Entertainment',
  'Medical Devices',
  'Metals and Mining',
  'MSME',
  'Oil and Gas',
  'Pharmaceuticals',
  'Ports',
  'Power',
  'Railways',
  'Real Estate',
  'Renewable Energy',
  'Retail',
  'Roads',
  'Science and Technology',
  'Services',
  'Steel',
  'Telecommunications',
  'Textiles',
  'Tourism and Hospitality']

export default INDUSTRY_LIST
